// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

if (!Date.prototype.addHours) {
  Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
  }
}

function Calc(pages, hours, slides) {
  this.bsp = 10;
  this.sbp = 5;
  this.ugm = 1;
  this.ppm = 1;
  this.acm = 1;
  this.svm = 1;
  this.spacing = 1;
  this.wc = 1;
  this.wcp = 0;
  this.deadline = parseInt(hours);
  this.pages = parseInt(pages);
  this.slides = parseInt(slides);
  this.twriter = 0;
  this.plagiarism = 0;
  this.priority = 0;
  this.grammarly = 0;
}

// Calc.prototype.bsprice = function(bsp) {
// 	return this.bsp = bsp;
// }

Calc.prototype.slideBsp = function() {
  return this.sbp;
}

Calc.prototype.words = function() {
  return this.pages * 275 * this.spacing;
}

Calc.prototype.wordsToText = function() {
  return this.words().toString() + ' words';
}

Calc.prototype.pagePrice = function() {
  return Math.round(this.bsp * this.ugm * this.ppm * this.acm * this.svm * this.spacing);
}

Calc.prototype.calcText = function() {
  var p = this.pages > 1 ? 'pages' : 'page';
  return this.pages.toString() + ' ' + p + ' x $';
}

Calc.prototype.setPages = function(p) {
  return isNaN(parseInt(p)) ? this.pages = 0 : this.pages = parseInt(p);
}

Calc.prototype.setSlides = function(s) {
  return isNaN(parseInt(s)) ? this.slides = 0 : this.slides = parseInt(s);
}

Calc.prototype.setDeadline = function(h) {
  return isNaN(parseInt(h)) ? this.deadline = 0 : this.deadline = h;
}

Calc.prototype.setUgm = function(u) {
  return isNaN(parseInt(u)) ? this.ugm = 1 : this.ugm = u;
}

Calc.prototype.setPpm = function(p) {
  return isNaN(parseInt(p)) ? this.ppm = 1 : this.ppm = p;
}

Calc.prototype.setAcm = function(a) {
  return isNaN(parseInt(a)) ? this.acm = 1 : this.acm = a;
}

Calc.prototype.setSvm = function(s) {
  return isNaN(parseInt(s)) ? this.svm = 1 : this.svm = s;
}

Calc.prototype.setSpacing = function(s) {
  return isNaN(parseInt(s)) ? this.spacing = 1 : this.spacing = parseInt(s);
}

Calc.prototype.addTopwriter = function(t) {
  this.twriter = parseInt(t);
}

Calc.prototype.addPlagiarism = function(p) {
  this.plagiarism = parseInt(p);
}

Calc.prototype.addPriority= function(p) {
  this.priority = parseInt(p);
}

Calc.prototype.addGrammarly = function(g) {
  this.grammarly = parseInt(g);
}

Calc.prototype.additional = function() {
  return this.twriter + this.plagiarism + this.priority + this.grammarly;
}

Calc.prototype.setWc = function(s) {
  return isNaN(parseInt(s)) ? this.wc = 1 : this.wc = parseInt(s);
}

Calc.prototype.getSpacing = function(s) {
  return this.spacing;
}

Calc.prototype.possible = function() {
  return this.deadline < this.pages;
}

Calc.prototype.slideText = function() {
  var s = this.slides > 1 ? 'slides' : 'slide';
  return this.slides.toString() + ' ' + s + ' x $'+this.sbp.toFixed(2);
}

Calc.prototype.slidePrice = function() {
  return this.slides * this.sbp;
}

Calc.prototype.deadlineText = function() {
  var dt = new Date();
  return dt.addHours(this.deadline).toString();
}

Calc.prototype.wcPrice = function() {
  var tt = this.price() + this.slidePrice();
  if(this.wc === 2) {
    this.wcp = tt * 0.25;
  } else if (this.wc === 3) {
    this.wcp = tt * 0.3;
  } else {
    this.wcp = 0;
  }

  return this.wcp;
}

Calc.prototype.price = function() {
  return Math.round(this.pagePrice() * this.pages);
}

Calc.prototype.totalPrice = function() {
  return this.price() + this.slidePrice() + this.additional() + this.wcp;
}

Calc.prototype.showInView = function(clc, tt1, tt, n) {
  // Show total pages
  clc.text(this.calcText().toString() + this.pagePrice().toFixed(n));

  // Show total price
  tt1.text(this.price().toFixed(n));
  tt.text(this.totalPrice().toFixed(n));
}

Calc.prototype.valToText = function(type, val) {
  var res = '';
  if (type === 'tos') {
    switch(parseInt(val)) {
      case 1:
        res = 'Writing';
        break;
      case 2:
        res = 'Editing';
        break;
      case 3:
        res = 'Powerpoint';
        break;
      case 4:
        res = 'Proofreading';
        break;
      default:
        res = '';
    }
  } else if (type === 'acl') {
    switch(parseInt(val)) {
      case 1:
        res = 'Doctorate';
        break;
      case 2:
        res = 'Master\'s';
        break;
      case 3:
        res = 'Undergrad. (yrs 3-4)';
        break;
      case 3:
        res = 'Undergrad. (yrs 3-4)';
        break;
      case 5:
        res = 'High School';
        break;
      default:
        res = '';
    }
  }

  return res;
}

function Rate(type, lvl) {
  this.type = type ? type: '';
  this.lvl = parseInt(lvl);
}

Rate.prototype.calculate = function(t, l) {
  this.type = t;
  this.lvl = parseInt(l);
  var mtpl = 1;
  switch(this.type) {
    case 'ugm':
      if (this.lvl <= 8) {
        mtpl = 1.75;
      } else if (this.lvl <= 12) {
        mtpl = 1.65;
      } else if (this.lvl <= 24) {
        mtpl = 1.55;
      } else if (this.lvl <= 48) {
        mtpl = 1.45;
      } else if (this.lvl <= 72) {
        mtpl = 1.35;
      } else if (this.lvl <= 120) {
        mtpl = 1.25;
      } else if (this.lvl <= 168) {
        mtpl = 1.15;
      } else {
        mtpl = 1;
      }
      break;
    case 'svm':
      if (this.lvl === 1) {
        mtpl = 1;
      } else {
        mtpl = 0.45;
      }
      break;
    case 'acm':
      if (this.lvl === 1) {
        mtpl = 1.75;
      } else if (this.lvl === 2) {
        mtpl = 1.55;
      } else if (this.lvl === 3) {
        mtpl = 1.35;
      } else if (this.lvl === 4) {
        mtpl = 1.15;
      } else if (this.lvl === 5) {
        mtpl = 1;
      }
      break;
    case 'ppm':
      if (this.lvl === 1) {
        mtpl  = 1;
      } else if (this.lvl === 2) {
        mtpl = 1.3;
      } else if (this.lvl === 3) {
        mtpl  = 1.1;
      } else if (this.lvl === 4) {
        mtpl  = 1.2;
      } else {
        mtpl  = 1;
      }
      break;
    default:
      mtpl = 1;
  }

  return mtpl;
}

Calc.prototype.rate = function(type, lvl) {
  var rate = new Rate();

  return rate.calculate(type, lvl);
}

$(document).ready(function() {
  var wcRadio = $('#mainOrderForm input[name="writer_category"]');

  wcRadio.each(function() {
    if($(this).is(':checked')) {
      $(this).parent().parent().addClass("active");
    } else {
      $(this).parent().parent().removeClass("active");
    }
  });

  wcRadio.click(function() {
    if($(this).is(':checked')) {
      $(this).parent().parent().addClass("active");
    }
    $(wcRadio).not(this).each(function(){
      $(this).parent().parent().removeClass("active");
    });
  });

  var matched = $("#files_div");

  var f_index = 1;

  $("#add_file").click(function(e) {
    e.preventDefault();
    if (matched.children().length < 10) {
      add_file();
      f_index++;
    } else {
      alert('Maximum 10 files allowed.');
    }
  });

  function add_file() {
    $("#files_div").append('<div class="file_wrapper"><label class="btn btn-outline-primary btn-file mr-2">Browse file... <input type="file" onchange="$(\'#upload-file-info'+f_index+'\').html(this.files[0].name)"></label><span class="label label-info" id="upload-file-info'+f_index+'">No file selected</span><button class="btn btn-outline-primary ml-2" onclick="remove_file(this)"><i class="fa fa-trash"></i></button></div>');
  }

  function remove_file(ele) {
    $(ele).parent().remove();
    return;
  }
  var pages = $('#pages3'),
    slides = $('#slides'),
    deadline_hrs = $("#mainOrderForm input[name='deadline']:checked").val(),
    pages_minus = $('#pg-minus3'),
    pages_plus = $('#pg-plus3'),
    total_pages = 1,
    dd_estimate = $('#ddEstimate');

  var calc = new Calc(pages.val(), deadline_hrs, slides.val()),
    calc_txt_ele = $('#calc_txt'),
    tt_price_ele1 = $('#tt_price1'),
    tt_price_ele = $('#tt_price'),
    word_count = $('#wordCount');

  var acl_rate = calc.rate('acm', $("#mainOrderForm input[name='academic_level']:checked").val()),
    stype_rate = calc.rate('svm', $("#mainOrderForm input[name='service_type']:checked").val()),
    deadline_rate = calc.rate('ugm', deadline_hrs),
    spacing_rate = $("#mainOrderForm input[name='spacing']:checked").val();

  calc.setDeadline(deadline_hrs);
  calc.setSvm(stype_rate);
  calc.setAcm(acl_rate);
  calc.setUgm(deadline_rate);
  calc.setSpacing(spacing_rate);

  var topic = $('#topic'),
    paper_type = $('#paper_type3'),
    subject = $('#subject3'),
    ptype_txt = $('#ptype_txt'),
    subject_txt = $('#subject_txt'),
    topic_txt = $('#topic_txt');

  ptype_txt.text(paper_type.val());
  subject_txt.text(subject.val());
  topic_txt.text(topic.val());

  topic.bind("change keypress keyup", function() {
    topic_txt.text(topic.val());
  });

  paper_type.change(function() {
    ptype_txt.text(paper_type.val());
  });

  subject.change(function() {
    subject_txt.text(subject.val());
  });

  pages_minus.click(function() {
    if(total_pages > 1) {
      total_pages--;
      pages.val(total_pages);
    }
    calc.setPages(total_pages);
    initCalc();
  });

  pages_plus.click(function() {
    if(total_pages < 200) {
      total_pages++;
      pages.val(total_pages);
    }
    calc.setPages(total_pages);
    if (calc.possible()) {
      showNotice();
    }
    initCalc();
  });

  pages.keyup(function() {
    total_pages = pages.val();
    if (isNaN(parseInt(total_pages))) {
      pages.val(1);
    }
    initCalc();
  });

  var sources = $('#sources'),
    src_minus = $('#src-minus'),
    src_plus = $('#src-plus'),
    total_sources = 0;

  src_minus.click(function() {
    if(total_sources > 0) {
      total_sources--;
      sources.val(total_sources);
    }
  });

  src_plus.click(function() {
    if(total_sources < 200) {
      total_sources++;
      sources.val(total_sources);
    }
  });

  sources.keyup(function() {
    total_sources = sources.val();
    if (isNaN(parseInt(total_sources))) {
      sources.val(0);
    }
    initCalc();
  });

  var slide_minus = $('#slides-minus'),
    slide_plus = $('#slides-plus'),
    total_slides = 0,
    slide_txt = calc.slideText(),
    slide_price = calc.slidePrice(),
    serv_summary = $('#serv_summary');

  calc.setSlides(slides.val());

  if(slides.val() > 0) {
    slide_txt = calc.slideText();
    slide_price = calc.slidePrice();
    if($('#slideTxt').length) {
      $('#slideTxt').html('<span>' + slide_txt +'</span><span class="value">$'+slide_price.toFixed(2)+'</span>');
    } else {
      serv_summary.append('<div class="item" id="slideTxt"></div>');
      $('#slideTxt').html('<span>' + slide_txt +'</span><span class="value">$'+slide_price.toFixed(2)+'</span>');
    }
  } else if (slides.val() < 1) {
    $('#slideTxt').remove();
  }

  slide_plus.click(function() {
    if(total_slides < 200) {
      total_slides++;
      slides.val(total_slides);
      calc.setSlides(total_slides);
      slide_txt = calc.slideText();
      slide_price = calc.slidePrice();
    }
    if(total_slides === 1) {
      serv_summary.append('<div class="item" id="slideTxt"></div>');
    }
    $('#slideTxt').html('<span>' + slide_txt +'</span><span class="value">$'+slide_price.toFixed(2)+'</span>');
    initCalc();
  });

  slide_minus.click(function() {
    if(total_slides > 0) {
      total_slides--;
      slides.val(total_slides);
      calc.setSlides(total_slides);
      slide_txt = calc.slideText();
      slide_price = calc.slidePrice();
    }
    $('#slideTxt').html('<span>' + slide_txt +'</span><span class="value">$'+slide_price+'</span>');
    if(total_slides < 1) {
      $('#slideTxt').remove();
    }
    initCalc();
  });

  slides.keyup(function() {
    total_slides = slides.val();
    if (isNaN(parseInt(total_slides))) {
      slides.val(0);
    }
    initCalc();
  });

  $("#mainOrderForm :radio[name='service_type']").change(function() {
    stype_rate = calc.rate('svm', $(this).filter(':checked').val());
    calc.setSvm(stype_rate);
    initCalc();
  });

  $("#mainOrderForm :radio[name='academic_level']").change(function() {
    acl_rate = calc.rate('acm', $(this).filter(':checked').val());
    calc.setAcm(acl_rate);
    initCalc();
  });

  $("#mainOrderForm :radio[name='format']").change(function() {
    initCalc();
  });

  $("#mainOrderForm :radio[name='deadline']").change(function() {
    deadline_hrs = $(this).filter(':checked').val();
    deadline_rate = calc.rate('ugm', deadline_hrs);
    calc.setUgm(deadline_rate);
    calc.setDeadline(deadline_hrs);
    if (calc.possible()) {
      showNotice();
    }
    initCalc();
  });

  $("#mainOrderForm :radio[name='spacing']").change(function() {
    spacing_rate = $(this).filter(':checked').val();
    calc.setSpacing(spacing_rate);
    initCalc();
  });

// writer category
  var w_category = $("#mainOrderForm input[name='writer_category']:checked").val();
  calc.setWc(w_category);
  var wc_price = calc.wcPrice();

  $("#mainOrderForm :radio[name='writer_category']").change(function() {
    w_category = $(this).filter(':checked').val();
    calc.setWc(w_category);
    wc_price = calc.wcPrice();
    if (w_category > 1) {
      if($('#wc_category').length) {
        $('#wc_category').html('<span>Writer category</span><span class="value">$'+wc_price.toFixed(2)+'</span>');
      } else {
        serv_summary.append('<div class="item" id="wc_category"></div>');
        $('#wc_category').html('<span>Writer category</span><span class="value">$'+wc_price.toFixed(2)+'</span>');
      }
    } else {
      $('#wc_category').remove();
    }

    initCalc();
  });

  var top_writer = $('#top_writer');
  top_writer.change(function() {
    if ($(this).is(':checked') == true) {
      var tw_price = parseInt($(this).val());
      calc.addTopwriter(tw_price);
      if($('#topWriter').length) {
        $('#topWriter').html('<span>Top Writer</span><span class="value">$'+tw_price.toFixed(2)+'</span>');
      } else {
        serv_summary.append('<div class="item" id="topWriter"></div>');
        $('#topWriter').html('<span>Top writer</span><span class="value">$'+tw_price.toFixed(2)+'</span>');
      }
    } else {
      $('#topWriter').remove();
      calc.addTopwriter(0);
    }
    initCalc();
  });

  var priority = $('#priority');
  priority.change(function() {
    if ($(this).is(':checked') == true) {
      var pri_price = parseInt($(this).val());
      calc.addPriority(pri_price);
      if($('#priorityTxt').length) {
        $('#priorityTxt').html('<span>Make my order high priority</span><span class="value">$'+pri_price.toFixed(2)+'</span>');
      } else {
        serv_summary.append('<div class="item" id="priorityTxt"></div>');
        $('#priorityTxt').html('<span>Make my order high priority</span><span class="value">$'+pri_price.toFixed(2)+'</span>');
      }
    } else {
      $('#priorityTxt').remove();
      calc.addPriority(0);
    }
    initCalc();
  });
// Plagiarism report
  var plagiarism = $('#plagiarism');
  plagiarism.change(function() {
    if ($(this).is(':checked') == true) {
      var plag_price = parseInt($(this).val());
      calc.addPlagiarism(plag_price);
      if($('#plagTxt').length) {
        $('#plagTxt').html('<span>Plagiarism Report</span><span class="value">$'+plag_price.toFixed(2)+'</span>');
      } else {
        serv_summary.append('<div class="item" id="plagTxt"></div>');
        $('#plagTxt').html('<span>Plagiarism Report</span><span class="value">$'+plag_price.toFixed(2)+'</span>');
      }
    } else {
      $('#plagTxt').remove();
      calc.addPlagiarism(0);
    }
    initCalc();
  });
// Grammarly report
  var grammarly = $('#grammarly');
  grammarly.change(function() {
    if ($(this).is(':checked') == true) {
      var gram_price = parseInt($(this).val());
      calc.addGrammarly(gram_price);
      if($('#gramTxt').length) {
        $('#gramTxt').html('<span>Grammarly report</span><span class="value">$'+gram_price.toFixed(2)+'</span>');
      } else {
        serv_summary.append('<div class="item" id="gramTxt"></div>');
        $('#gramTxt').html('<span>Grammarly report</span><span class="value">$'+gram_price.toFixed(2)+'</span>');
      }
    } else {
      $('#gramTxt').remove();
      calc.addGrammarly(0);
    }
    initCalc();
  });

  function showNotice() {
    $('#noticeModal #msg').text('It takes approximately 1 hour to complete 1 double spaced page. We suggest changing the deadline to a longer one.');
    $('#noticeModal').modal('show');
  }

  function initCalc() {
    calc.setWc(w_category);
    wc_price = calc.wcPrice();
    if (w_category > 1) {
      if($('#wc_category').length) {
        $('#wc_category').html('<span>Writer category</span><span class="value">$'+wc_price.toFixed(2)+'</span>');
      } else {
        serv_summary.append('<div class="item" id="wc_category"></div>');
        $('#wc_category').html('<span>Writer category</span><span class="value">$'+wc_price.toFixed(2)+'</span>');
      }
    } else {
      $('#wc_category').remove();
    }
    word_count.text(calc.wordsToText());
    dd_estimate.text(calc.deadlineText());
    word_count.text(calc.wordsToText());
    calc.showInView(calc_txt_ele, tt_price_ele1, tt_price_ele, 2);
  }

  initCalc();
});
